import React, {useRef} from 'react';
import {Permissions} from '../../../config'

// material-ui
import {createUseStyles} from "react-jss";
import {Switch} from "@mui/material";
import {Sort, ArrowDropUp, ArrowDropDown} from "@mui/icons-material";
import {Select} from "@mui/material";
import {useTable, useSortBy, useFilters, useExpanded, usePagination} from 'react-table'

//i18n
import { useTranslation } from 'react-i18next';
import {Table, TableBody, TableCell, TableHead, TableRow, MenuItem} from "@mui/material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import exStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles from "../../../assets/jss/material-dashboard-pro-react/components/tableStyle";


function DefaultColumnFilter ({column: { Header, filterValue, setFilter}}) {
  return (
    <CustomInput
      formStyle={{marginTop: '0px', paddingTop: '0'}}
      inputProps={{
        value: filterValue || '',
        onChange: e => setFilter(e.target.value || undefined),
        placeholder: Header
      }}
    />
  );
};

function SelectStatus ({column: { filterValue, setFilter, preFilteredRows, id } }) {
  return (
    <Select
      MenuProps={{ className: 'selectMenu', style: {zIndex: 35001} }}
      classes={{ select: 'select' }}
      value={filterValue || ''}
      onChange={e => {setFilter(e.target.value || '')}}
      inputProps={{ name: "status",  id: "status-select"}}
      variant="standard"
    >
     <MenuItem classes={{root: 'selectMenuItem', selected: 'selectMenuItemSelected'}} value=''> All </MenuItem>
     <MenuItem classes={{root: 'selectMenuItem', selected: 'selectMenuItemSelected'}} value='Approved'> Approved </MenuItem>
     <MenuItem classes={{root: 'selectMenuItem', selected: 'selectMenuItemSelected'}} value='Pending'> Pending </MenuItem>
    </Select>
  )
}

function SelectSpecial ({column: { filterValue, setFilter, preFilteredRows, id, data } }) {
  const options = React.useMemo(() => {
    const options = new Set();
    data.forEach(row => {
        options.add(row.name);
      }
    );
    return [...options.values()]
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Select
      MenuProps={{ className: 'selectMenu', style: {zIndex: 35001} }}
      classes={{ select: 'select' }}
      value={filterValue || ''}
      onChange={e => {setFilter(e.target.value || '')}}
      inputProps={{ name: "special",  id: "special-select"}}
      variant="standard"
    >
      <MenuItem classes={{root: 'selectMenuItem', selected: 'selectMenuItemSelected'}} value=''> All </MenuItem>
      <MenuItem classes={{root: 'selectMenuItem', selected: 'selectMenuItemSelected'}} value='Almennir notendur'> Almennir notendur </MenuItem>
      {options.filter(function (el) { return el !== ''; }).map((option, i) => (
        <MenuItem key={i} classes={{root: 'selectMenuItem', selected: 'selectMenuItemSelected'}} value={option+' '}> {option} </MenuItem>
      ))}
    </Select>
  )
}

function SelectCategory ({column: { filterValue, setFilter, preFilteredRows, id } }) {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row =>
      row.original.torgid_offer_category_nxns ? row.original.torgid_offer_category_nxns.map((item, i) =>
        options.add( item.torgid_offer_category?.name+' ')
      ) : ''
    );
    return [...options.values()]
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Select
      MenuProps={{ className: 'selectMenu', style: {zIndex: 35001} }}
      classes={{ select: 'select' }}
      value={filterValue || ''}
      onChange={e => {setFilter(e.target.value || '')}}
      inputProps={{ name: "category",  id: "category-select"}}
      variant="standard"
    >
      <MenuItem classes={{root: 'selectMenuItem', selected: 'selectMenuItemSelected'}} value=''> All </MenuItem>
      {options.sort().map((option, i) => (
        <MenuItem key={i} classes={{root: 'selectMenuItem', selected: 'selectMenuItemSelected'}} value={option}> {option} </MenuItem>
      ))}
    </Select>
  )
}

function SelectActive ({column: { filterValue, setFilter, preFilteredRows, id } }) {
  return (
    <Select
      MenuProps={{ className: 'selectMenu', style: {zIndex: 35001} }}
      classes={{ select: 'select' }}
      value={filterValue || ''}
      onChange={e => {setFilter(e.target.value || '')}}
      inputProps={{ name: "active",  id: "active-select"}}
      variant="standard"
    >
      <MenuItem classes={{root: 'selectMenuItem', selected: 'selectMenuItemSelected'}} value=' '> All </MenuItem>
      <MenuItem classes={{root: 'selectMenuItem', selected: 'selectMenuItemSelected'}} value='Active'> Active </MenuItem>
      <MenuItem classes={{root: 'selectMenuItem', selected: 'selectMenuItemSelected'}} value='Inactive'> Inactive </MenuItem>
    </Select>
  )
}


const style = {
  tableTitle: {
    position: 'absolute',
    top: '10px',
    color: '#09b1c5',
    fontWeight: 'bold',
    fontSize: '18px'
  },
  pagination_row:{
    margin: '10px auto',
    textAlign: 'center',
    paddingTop: '20px',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  tableBodyRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f9f9f9',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#fff',
    },
  },
  tableResponsive: {
    // width: "100%",
    minHeight: "0.1%",
    overflowX: "auto"
  },
  ...exStyles,
  ...styles
};

const useStyles = createUseStyles(style);

// const tableData = offersByCompany.results ? offersByCompany.results.map((value, index) => {
//   return ([
//     index+1,
//     <img key={index} title={value.image} src={value.image} style={{width: '25px', height: 'auto'}}/>,
//     value.title,
//     value.approved ?
//       <Button color="success" size="sm" className={classes.actionButton}>{t('approved')}</Button> :
//       <Button color="warning" size="sm" className={classes.actionButton}>{t('pending')}</Button>,
//     value.torgid_company.name && value.torgid_company.name,
//     value.torgid_special_company ? <img key={index+'spec'} src={value.torgid_special_company.logo} style={{width: '25px', height: '25px', padding: '4px'}}/> : '',

//     value.torgid_offer_category_nxns && value.torgid_offer_category_nxns.map((item, i) => {
//       return item.torgid_offer_category ? (item.torgid_offer_category.icon && item.torgid_offer_category.name ? <img key={i} src={item.torgid_offer_category.icon} title={item.torgid_offer_category.name} style={{width: '25px', height: '25px', padding: '4px'}}/> : '') : ''
//     }),
//     value.start_date || '',
//     value.end_date || '',
//     value.is_active ? <span style={{color: 'green', fontSize: '30px', paddingLeft: '10px'}}>●</span> : <span style={{color: 'red', fontSize: '30px', paddingLeft: '10px'}}>●</span>,
//     [
//       infoButton(value),
//       OFFER_DETAILS && reportButton(value),
//       CU_OFFER_UPDATE && updateButton(value),
//       CU_OFFER_DELETE && <DeleteButton key={value.id+'_delete'} action={deleteOffer({...value})} setLimit={()=>{}} />
//     ]
//   ]);
// }) :  [[ ]];


const OfferTable = (props) => {
  const {dataGroup} = props;
  const classes = useStyles();
  const userStore = JSON.parse(localStorage.getItem('user'));
  const userPermission = userStore.permission;
  const userCompanyIds = userStore.companyIds;
  const OFFER_DETAILS = userPermission.includes(Permissions.OFFER_DETAILS);
  const CU_OFFER_UPDATE = userPermission.includes(Permissions.CU_OFFER_UPDATE);
  const CU_OFFER_DELETE = userPermission.includes(Permissions.CU_OFFER_DELETE);
  const data = props.data;
  const { t } = useTranslation();
  const cardRef = useRef();

  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        hideSorting: true,
        disableFilters: true,
        Cell: ({ cell }) => {
          return parseInt(cell.row.id)+1
        },
      },
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: t('img'),
        disableFilters: true,
        accessor: (values) => {
          let img = values.offer_images && values.offer_images.length>0 ? values.offer_images[0].image : values.image;
          return <img key={values.id+"img"} title={img} src={img} style={{width: '25px', height: 'auto'}}/>
        },
      },
      {
        Header: t('title'),
        accessor: 'title',
      },
      {
        Header: t('status'),
        accessor: (values) => {
          return values.approved ? "Approved" : 'Pending'
        },
        Cell: ({ cell }) => {
          const {value} = cell;
          return <Button color={value === 'Approved' ? "success" : 'warning'} size="sm" className={classes.actionButton}>{value}</Button>
        },
        Filter: SelectStatus,
        filter: 'equals',
      },
      {
        Header: t('company'),
        accessor: (values) => {
          return values.torgid_company.name
        },
      },
      {
        Header: t('special'),
        accessor: (values) => {
          if(values.group_ids) {
            return values.group_ids.split(',').map(x => +x).map(item => dataGroup.find(spec => spec.id === item)?.name+' ');
          } else if(values.torgid_special_company) {
            return values.torgid_special_company.name+' '
          } else {
            return 'Almennir notendur ';
          }
        },
        Cell: ({ cell }) => {
          const {value, row} = cell;
          if(row.original.group_ids) {
            return row.original.group_ids.split(',').map(x => +x).map((item, i) => {
              let specItem = dataGroup.find(spec => spec.id == item);
              return !specItem ? 'Almennir notendur' : specItem.logo && specItem.name ? <img key={item+'_'+i+'_specImg'} src={specItem.logo} title={specItem.name} style={{width: '25px', height: '25px', padding: '4px'}}/> : ''
            });
          } else if(row.original.torgid_special_company) {
            return <img key={value.id+'spec'} src={row.original.torgid_special_company.logo} style={{width: '25px', height: '25px', padding: '4px'}}/>
          } else {
            return 'Almennir notendur';
          }
        },
        Filter: SelectSpecial,
        data: dataGroup,
        filter: 'includes',
        minWidth: 100,
      },
      {
        Header: t('category'),
        accessor: (values) => {
          return values.torgid_offer_category_nxns ? values.torgid_offer_category_nxns.map((item, i) => {
            return item.torgid_offer_category?.name+' ';
          }) : ''
        },
        Cell: ({ cell }) => {
          const {value, row} = cell;
          // return row.original.torgid_special_company ? <img key={value.id+'spec'} src={row.original.torgid_special_company.logo} style={{width: '25px', height: '25px', padding: '4px'}}/> : ''
          return row.original.torgid_offer_category_nxns && row.original.torgid_offer_category_nxns.map((item, i) => {
            return item.torgid_offer_category ? (item.torgid_offer_category.icon && item.torgid_offer_category.name ? <img key={i} src={item.torgid_offer_category.icon} title={item.torgid_offer_category.name} style={{width: '25px', height: '25px', padding: '4px'}}/> : '') : ''
          })
        },

        //
        Filter: SelectCategory,
        filter: 'includes',
        minWidth: 120,
      },
      {
        Header: t('offer.start_date'),
        accessor: 'start_date',
        minWidth: 110,
      },
      {
        Header: t('expires_on'),
        accessor: 'end_date',
        minWidth: 120,
      },
      {
        Header: t('is_active'),
        accessor: (values) => {
          return values.is_active ? "Active " : 'Inactive '
        },
        Cell: ({ cell }) => {
          const {value} = cell;
          return <Switch
            checked={value === 'Active '}
            onChange={e => props.changeOfferActive(cell.row.original)}
          />
          // return <span style={{color: value === 'Active ' ? 'green' : 'red', fontSize: '30px', paddingLeft: '10px'}}>●</span>
        },
        Filter: SelectActive,
        filter: 'includes',
      },
      {
        Header: t('actions'),
        hideSorting: true,
        disableFilters: true,
        Cell: ({ cell }) => {
          return (
            <div>
              {props.infoButton(cell.row.original)}
              {OFFER_DETAILS && ((cell.row.original.group_ids && cell.row.original.group_ids.split(',').length === 1) || userCompanyIds.includes(cell.row.original.torgid_company_id)) && props.reportButton(cell.row.original)}
              {OFFER_DETAILS && ((cell.row.original.group_ids && cell.row.original.group_ids.split(',').length === 1) || userCompanyIds.includes(cell.row.original.torgid_company_id)) && props.archivedButton(cell.row.original)}
              {CU_OFFER_UPDATE && ((cell.row.original.group_ids && cell.row.original.group_ids.split(',').length === 1) || userCompanyIds.includes(cell.row.original.torgid_company_id)) && props.updateButton(cell.row.original)}
              {CU_OFFER_DELETE && props.deleteButton(cell.row.original)}
            </div>
          )
        },
      },
    ],
    []
  );


  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: 10 },
      autoResetExpanded: false,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? <ArrowDropDown/> : <ArrowDropUp/>) : <Sort style={{fontSize: '14px'}} />;
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const onChangePage = (page) => {
    gotoPage(page);
    cardRef.current.scrollIntoView();
  };



  // Render the UI for your table
  return (
    // If you're curious what props we get as a result of calling our getter functions (getTableProps(), getRowProps())
    // Feel free to use console.log()  This will help you better understand how react table works underhood.
    <React.Fragment>
      <div className={classes.tableResponsive}>
        <div ref={cardRef}/>
        <Table className={classes.table}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow className={classes.tableRow + " " + classes.tableRowHead} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell variant='head'
                    className={classes.tableHeadCell}
                   {...column.getHeaderProps({style: { minWidth: column.minWidth },})}
                  >
                    <div {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {!column.hideSorting && generateSortingIndicator(column)}
                    </div>
                    {column.canFilter && column.render("Filter")}
                    {/*<Filter column={column} />*/}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, key) => {
              prepareRow(row)
              return (
                <React.Fragment key={row.getRowProps().key}>
                  <TableRow key={key} hover={true} className={classes.tableBodyRow}>
                    {row.cells.map(cell =>
                      <TableCell className={classes.tableCell} key={key} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    )}
                  </TableRow>
               </React.Fragment>
              )
            })}
          </TableBody>
        </Table>
      </div>

      <GridContainer className={classes.pagination_row} justifyContent="space-between" container>
        <GridItem xs={3}>
          <Button color="success" onClick={() => onChangePage(0)} disabled={!canPreviousPage}>{"<<"}</Button> {' '}
          <Button color="success" onClick={() => onChangePage(pageIndex-1)} disabled={!canPreviousPage}>{"<"}</Button>
        </GridItem>
        <GridItem md={2} style={{ marginTop: 15 }}>
          Page{" "} <strong> {pageIndex + 1} of {pageOptions.length}</strong>
        </GridItem>
        <GridItem md={1}>
          <CustomInput
            formControlProps={{
              fullWidth: true
            }}
            formStyle={{marginTop: '0px'}}
            inputStyle={{marginTop: '0px'}}
            inputProps={{
              type: 'number',
              min: 1,
              max: pageOptions.length,
              defaultValue: pageIndex + 1,
              // value: filterValue || "",
              onChange: onChangeInInput,
              placeholder: `Page`
            }}
          />
        </GridItem>
        <GridItem md={2} style={{ marginTop: 15 }}>
          <select value={pageSize} onChange={onChangeInSelect} className="select_filter">
            {[10, 30, 50, 70, 100].map(pageSize => ( <option key={pageSize} value={pageSize}> Show {pageSize} </option>))}
          </select>
        </GridItem>
        <GridItem md={3}>
          <Button color="success" onClick={() => onChangePage(pageIndex + 1)} disabled={!canNextPage}>{">"}</Button> {' '}
          <Button color="success" onClick={() => onChangePage(pageCount - 1)} disabled={!canNextPage}>{">>"}</Button>
        </GridItem>
      </GridContainer>

    </React.Fragment>
  )

};

export default OfferTable;