import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import {Link, NavLink, useNavigate} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {withTranslation } from 'react-i18next';
import {Permissions } from '../../config'

// material-ui
import {createUseStyles} from "react-jss";
import {Assignment, Edit, AddAlert, Visibility, ListAlt} from "@mui/icons-material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SnackbarCustom from "components/Snackbar/Snackbar.js";
import DeleteButton from "components/Main/DeleteButton.js";

import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import OfferPreview from "./offerPreview";
import OfferReport from "./offerReport";
import OfferTable from "./offerTable";

import {getOffers, deleteOffer, changeOfferStatus} from "../../store/actions/offer";
import {getSpecialCompany} from "../../store/actions/specialCompany";
import {LoaderSpinner} from "../../components/Loader";

const style = {
  ...styles,
  ...stylesAlert,
};

const useStyles = createUseStyles(style);

const Offers = ({ t }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {offers, loading} = useSelector( state => state.offer );
  const {status, message, type} = useSelector( state => state.offer.notification );
  const [alert, setAlert] = useState(null);
  const userPermission = JSON.parse(localStorage.getItem('user')).permission;
  const {specialCompanies, loading:loadingGroup } = useSelector( state => state.specialCompanies )

  useEffect(() => {
    dispatch(getSpecialCompany());
    dispatch(getOffers(false));
  }, [dispatch]);


  const hideAlert = () => { setAlert(null) };

  const infoButton = (offer) => {
    return (
      <Button key={offer.id + 'info'} color="info" className={classes.actionButton}
              title={t('preview')}
              onClick={() => setAlert( <OfferPreview data={offer} onCancel={() => hideAlert()} /> )}>
        <Visibility className={classes.icon} />
      </Button>
    );
  };

  const updateButton = (offer) => {
    return (
      <Button
        color="success"
        className={classes.actionButton}
        title={t('edit')}
        onClick={()=> navigate('/add-offer', { state: {"offer": offer} })}
      >
        <Edit className={classes.icon} />
      </Button>
    );
  };

  const reportButton = (offer) => {
    return (
      <Button color="warning" key={offer.id + '_report'} className={classes.actionButton}
              title={t('statistic')}
              onClick={() => setAlert(<OfferReport data={offer} onCancel={() => hideAlert()} />)}>
        <ListAlt className={classes.icon} />
      </Button>
    );
  };
  const deleteButton = (offer) => {
    return (
      <DeleteButton key={offer.id+'_delete'} action={deleteOffer({...offer})} setLimit={()=>{}}/>
    );
  };


  const changeOfferActive = (offer) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={offer.is_active ?  t('offer.change_status_deactive') : t('offer.change_status_active')}
        onConfirm={() => successChange(offer)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t('yes')}
        cancelBtnText={t('cancel')}
        showCancel
      />
    );
  };

  const successChange = (offer) => {
    dispatch(changeOfferStatus(offer, 'sa'));
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t('updated')}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        {t('offer_updated')}
      </SweetAlert>
    );
  };


  const companyView = (data) => {
    return (
      <NavLink to={{pathname: '/company/'+data.id, state:{data}}} target="_blank"  style={{ color: '#0a6b0e', FONTWEIGHT: 500, textDecoration: 'underline' }}>
        {data.name}
      </NavLink>
    );
  };

  if (loading || loadingGroup) return (<LoaderSpinner/>);

  return  <GridContainer>
    {alert}
    <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message={message} open={status} />
    <GridItem xs={12}>
      <Card>
        <CardHeader color="info" icon>
          <GridContainer justifyContent="space-between" container>
            <GridItem md={3}>
              <CardHeader>
                <CardIcon color="rose"> <Assignment /> </CardIcon>
                <span className={classes.tableTitle}>{t('menu.list_of_offers')} </span>
              </CardHeader>
            </GridItem>
            <GridItem>
              { userPermission.includes(Permissions.OFFER_CREATE) && <Link to="/add-offer" > <Button color="rose" className={classes.addButton}> {t('offer.add_new')} </Button> </Link> }
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>

          <OfferTable
            data={offers}
            dataGroup={specialCompanies.results}
            infoButton={infoButton}
            updateButton={updateButton}
            reportButton={reportButton}
            deleteButton={deleteButton}
            changeOfferActive={changeOfferActive}
          />

        </CardBody>
      </Card>
    </GridItem>
  </GridContainer>
};

export default withTranslation('',{ withRef: true })(Offers);