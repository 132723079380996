import React from 'react';
import { useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';

// material-ui
import {Close} from '@mui/icons-material';
import SweetAlert from "react-bootstrap-sweetalert";
import stylesAlert from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Button from "../CustomButtons/Button.js";
import {createUseStyles} from "react-jss";

const style = {
  actionButton: {
    padding: '5px 2px 5px 5px',
    marginLeft: '5px'
  },
  ...stylesAlert
};

const useStyles = createUseStyles(style);


const DeleteButton = ({t, action, setLimit, msg = '', disabledbtn = false}) => {
  const classes = useStyles();
  const dispatch = useDispatch ();
  const [alert, setAlert] = React.useState(null);

  const hideAlert = () => { setAlert(null) };

  const successDelete = () => {
    dispatch(action);
    if(setLimit) {
      setLimit();
    }
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t('deleted')}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        {t('delete_msg')}
      </SweetAlert>
    );
  };

  const warningWithConfirmMessage = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px", color: "#000" }}
        title={t('are_you_sure')}
        onConfirm={() => successDelete()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t('delete_yes')}
        cancelBtnText={t('cancel')}
        showCancel
      />
    );
  };

  return <React.Fragment>
    {alert}
    <Button
      color="danger"
      className={classes.actionButton}
      onClick={() => warningWithConfirmMessage()} disabled={disabledbtn}
      title={t('delete')}
    >
      {msg === '' ? <Close className={classes.icon} /> : <span style={{padding: '2px 7px'}}>{msg}</span>}
    </Button>
  </React.Fragment>
};

export default withTranslation('',{ withRef: true })(DeleteButton);

