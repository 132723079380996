import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";


export const loginUser = ({login,password }) => async dispatch => {
  dispatch({type: actionTypes.LOGIN_START});
  try {
    const res = await axios({
      url: '/api/auth/admin_login',
      method: 'POST',
      data: {
        email: login,
        password: password
      }
    });

    if(res.data.message === 'Successfully login.' && res.data.code === 200) {
      const data = {
        "access_token": res.data.results.access_token,
        "token_type": res.data.results.token_type,
        "roles": res.data.results.roles,
        "permission": res.data.results.permission.map((value) => (value.name)),
        "user": res.data.results.user[0],
        "companyIds": res.data.results.user.map((value) => value.torgid_company_id),
        "companies": res.data.results.user.filter((item) => item.torgid_company !== null).map((value) => value.torgid_company ),
        "groupIds": res.data.results.user.filter(item => item.torgid_special_company_id !== null).map((value) => value.torgid_special_company_id),
        "groupTypes": res.data.results.user.filter(item => item.torgid_special_company_id !== null).map((value) => value.torgid_special_company ? value.torgid_special_company.type : ''),
      };
      dispatch({type: actionTypes.LOGIN_SUCCESS, data: data});
      localStorage.setItem('user', JSON.stringify(data));
      window.location.reload();
    }

  } catch (error) {
    dispatch({type: actionTypes.LOGIN_FAIL, data: error.response ? error.response.data.message : 'Something went wrong'});
  }
};

export const resetPassword = (email) => async dispatch => {
  dispatch({type: actionTypes.RESET_START});

  try {
    const res = await axios({
      url: '/api/user/reset_password',
      method: 'POST',
      data: {
        email: email,
      }
    });

    const data = {
      "msg": res.data.message,
      "email": email,
    };
    dispatch({type: actionTypes.RESET_SUCCESS, data: data});
  } catch (error) {
    dispatch({type: actionTypes.RESET_FAIL, data: error.response ? error.response.data.message : 'Something went wrong'});
  }
};


export const loginByPhone = (phone) => async dispatch => {
  dispatch({type: actionTypes.LOGIN_PHONE_START});

  try {
    const res = await axios({
      url: '/api/auth/phone_login',
      method: 'POST',
      data: {
        type: 'admin',
        phone: '+354'+phone
      }
    });
    if(res.data.message === 'SMS sent' && res.data.code === 200) {
      const data = {
        "msg": res.data.message,
        "phone": phone,
      };
      dispatch({type: actionTypes.LOGIN_PHONE_SUCCESS, data: data});
    }
    if(res.data.message === 'Successfully login.' && res.data.code === 200) {
      const data = {
        "access_token": res.data.results.access_token,
        "token_type": res.data.results.token_type,
        "roles": res.data.results.roles,
        "permission": res.data.results.permission.map((value) => (value.name)),
        "user": res.data.results.user[0],
        "companyIds": res.data.results.user.map((value) => value.torgid_company_id ),
        "companies": res.data.results.user.filter((item) => item.torgid_company !== null).map((value) => value.torgid_company ),
        "groupIds": res.data.results.user.filter(item => item.torgid_special_company_id !== null).map((value) => value.torgid_special_company_id),
      };
      dispatch({type: actionTypes.LOGIN_SUCCESS, data: data});
      localStorage.setItem('user', JSON.stringify(data));
      window.location.reload();
    }

  } catch (error) {
    const res = error?.response?.data;
    const message = (res && res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res && res.message + ". " + message;
    dispatch({type: actionTypes.LOGIN_PHONE_FAIL, data: errorMessage});
  }
};

export const loginSms = (phone, code) => async dispatch => {
  dispatch({type: actionTypes.LOGIN_START});

  try {
    const res = await axios({
      url: '/api/auth/sms_verify_login',
      method: 'POST',
      data: {
        phone: '+354'+phone,
        code: code,
        type: 'admin',
      }
    });

    if(res.data.message === 'Successfully login.' && res.data.code === 200) {
      const data = {
        "access_token": res.data.results.access_token,
        "token_type": res.data.results.token_type,
        "roles": res.data.results.roles,
        "permission": res.data.results.permission.map((value) => (value.name)),
        "user": res.data.results.user[0],
        "companyIds": res.data.results.user.map((value) => value.torgid_company_id ),
        "companies": res.data.results.user.filter((item) => item.torgid_company !== null).map((value) => value.torgid_company ),
      };
      dispatch({type: actionTypes.LOGIN_SUCCESS, data: data});
      localStorage.setItem('user', JSON.stringify(data));
      window.location.reload();
    }

  } catch (error) {
    dispatch({type: actionTypes.LOGIN_FAIL, data: error.response.data.message});
  }
};

export const logout = (navigate) => async dispatch => {
  dispatch({type: actionTypes.LOGOUT_START});

  try {
    let tok = JSON.parse(localStorage.getItem('user'));
    const res = await axios({
      url: '/api/auth/logout',
      method: 'POST',
      headers: {
        'Authorization': tok.token_type +' '+tok.access_token,
        'Ability': 'admin'
      }
    });

    dispatch({type: actionTypes.LOGOUT_SUCCESS, data: res.data.results});
    localStorage.clear();
    navigate('/login');

  } catch (error) {
    dispatch({type: actionTypes.LOGOUT_FAIL, data: error.response.data.message});
  }

};
