import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { withTranslation } from 'react-i18next';
import { Permissions } from '../../../config'

// material-ui
import {createUseStyles} from 'react-jss'
import {Assignment, Edit, AddAlert, Visibility, ListAlt, Archive, Close} from "@mui/icons-material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SnackbarCustom from "components/Snackbar/Snackbar.js";

import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import {
  deleteOffer,
  changeOfferStatus,
  changeOfferStatusArchive,
  getOfferByCompaniesAndGroups, createUpdateOffer
} from "../../../store/actions/offer";
import OfferReport from "./offerReport";
import OfferPreview from "./offerPreview";
import OfferTable from "./offerTable";
import {getSpecialCompany} from "../../../store/actions/specialCompany";
import {LoaderSpinner} from "../../../components/Loader";

const style = {
  ...styles,
  ...stylesAlert
};

const useStyles = createUseStyles(style);

const MyOffers = ({ t }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {offersByCompany, loading} = useSelector( state => state.offer );
  const {status, message, type} = useSelector( state => state.offer.notification );
  const [alert, setAlert] = useState(null);
  const dispatch = useDispatch ();
  let store = JSON.parse(localStorage.getItem('user'));
  const userPermission = store.permission;
  const companyUser = store.user.torgid_company;
  const companyIds =store.companyIds;
  const groupIds = store.groupIds;
  let user_is_group_admin = store && store.roles.length === 1 && store.roles[0] === 'group_admin';
  const {specialCompanies, loading:loadingGroup } = useSelector( state => state.specialCompanies )

  useEffect(() => {
    dispatch(getSpecialCompany());
    if(user_is_group_admin) {
      dispatch(getOfferByCompaniesAndGroups(null, groupIds));
    } else {
      dispatch(getOfferByCompaniesAndGroups(companyIds, groupIds));
    }

  }, [dispatch]);

  const hideAlert = () => { setAlert(null) };

  const offerPreviewModal = (offer) => {
    setAlert( <OfferPreview data={offer} onCancel={() => hideAlert()} /> );
  };

  const infoButton = (offer) => {
      return (
        <Button key={offer.id + 'info'} title={t('preview')} color="info" className={classes.actionButton}  onClick={() => offerPreviewModal(offer)}>
          <Visibility className={classes.icon} />
        </Button>
      );
  };

  const updateButton = (offer) => {
    return (
      <Button
        color="success"
        className={classes.actionButton}
        title={t('edit')}
        onClick={()=> navigate('/add-my-offer', { state: {"offer": offer} })}
      >
        <Edit className={classes.icon} />
      </Button>
    );
  };

  const reportButton = (offer) => {
    return (
      <Button color="info" key={offer.id + 'my_offer_report'} className={classes.actionButton}
              title={t('statistic')}
              onClick={() => setAlert(<OfferReport data={offer} onCancel={() => hideAlert()} />)}>
        <ListAlt className={classes.icon} />
      </Button>
    );
  };

  const deleteOfferFn = (data) => {
    if(data.group_ids.split(',').length === groupIds.length) {
      dispatch(deleteOffer({...data}, 'offersByCompany'));
    } else {
      data.group_ids = data.group_ids.split(',').map(x => +x).filter(item => !groupIds.includes(item)).join(',');
      let newData = {
        "about": {...data},
        "category": {torgid_category_id: data.torgid_offer_category_nxns.map(item => item.torgid_offer_category_id)},
      }
      dispatch(createUpdateOffer({update:true, ...newData},  'my-offers'));
    }
  }

  const deleteOfferButton = (offer) => {
    return (
      <Button
        color="danger"
        key={offer.id + 'delete'}
        className={classes.actionButton}
        title={t('delete')}
        onClick={() => deleteOfferFn(offer)}
      >
        <Close className={classes.icon} />
      </Button>
    );
  };

  const archivedButton = (offer) => {
    return (
      <Button color="warning" key={offer.id + 'my_offer_archived'} className={classes.actionButton} onClick={() => offerArchive(offer)}
              title={t('archive')}
      >
        <Archive className={classes.icon} />
      </Button>
    );
  };



  const changeOfferActive = (offer) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={offer.is_active ?  t('offer.change_status_deactive') : t('offer.change_status_active')}
        onConfirm={() => successChange(offer)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t('yes')}
        cancelBtnText={t('cancel')}
        showCancel
      />
    );
  };

  const successChange = (offer) => {
    dispatch(changeOfferStatus(offer, 'cu'));
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t('updated')}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        {t('offer_updated')}
      </SweetAlert>
    );
  };



  const offerArchive = (offer) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t('offer.archive')}
        onConfirm={() => offerSuccessArchive(offer)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t('yes')}
        cancelBtnText={t('cancel')}
        showCancel
      />
    );
  };

  const offerSuccessArchive = (offer) => {
    dispatch(changeOfferStatusArchive(offer));
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={'Sett í geymslu!'}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        Valið tilboð þitt hefur verið sett í geymslu!
      </SweetAlert>
    );
  };



  if (loading || loadingGroup) return (<LoaderSpinner/>);


  return  <GridContainer>
    {alert}
    <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message={message} open={status} />
    <GridItem xs={12}>
      <Card>
        <CardHeader color="info" icon>
          <GridContainer justifyContent="space-between" container>
            <GridItem md={3}>
              <CardHeader >
                <CardIcon color="rose"> <Assignment /> </CardIcon>
                <span className={classes.tableTitle}>{t('menu.list_of_offers')} </span>
              </CardHeader>
            </GridItem>
            <GridItem>
              {
                userPermission.includes(Permissions.CU_OFFER_CREATE) && <Link to="/add-my-offer" >
                  <Button color="rose" className={classes.addButton}> {t('offer.add_new')} </Button>
                </Link>
              }
              {/*{*/}
              {/*  userPermission.includes(Permissions.CU_OFFER_CREATE) && companyIds.length === 1 && offersByCompany.results && offersByCompany.results.length > companyUser.offer_limit ?*/}
              {/*  <Button color="success" disabled={true} className={classes.addButton}> Your limit is over </Button> :*/}
              {/*    <Link to="/app/add-my-offer" >*/}
              {/*      <Button color="success" className={classes.addButton}> {t('offer.add_new')} </Button>*/}
              {/*    </Link>*/}
              {/*}*/}
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>

          <OfferTable
            data={offersByCompany}
            dataGroup={specialCompanies.results}
            infoButton={infoButton}
            reportButton={reportButton}
            updateButton={updateButton}
            deleteButton={deleteOfferButton}
            changeOfferActive={changeOfferActive}
            archivedButton={archivedButton}
          />

        </CardBody>
      </Card>
    </GridItem>
  </GridContainer>
};

export default withTranslation('',{ withRef: true })(MyOffers);