import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import { Provider } from 'react-redux'
import store from './store'
import './locales/i18n';
import App from "./app";
import "./assets/css/material-dashboard-pro-react.css"
import "./assets/scss/material-dashboard-pro-react.scss"


const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </Provider>
);
