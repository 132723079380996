import React from "react";
import Datetime from "react-datetime";
import {withTranslation} from 'react-i18next';

// @mui/material components
import { Face, Phone, Email, Lock, FiberManualRecord, PersonPinCircle, CallToAction} from "@mui/icons-material";
import {
  InputAdornment,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  Switch,
  Select,
  MenuItem,
  TextField
} from "@mui/material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PictureUpload from "components/CustomUpload/PictureUpload.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Autocomplete from "@mui/material/Autocomplete";


class Step1 extends React.Component {
  constructor(props) {
    super(props);
    const prop = props.user.torgid_user ? props.user.torgid_user : props.user.length>1 ? props.user[0].torgid_user : props.user;
    this.state = {
      id:  prop.id || '',
      user: prop.email || prop.phone || '',
      userState: prop.email || prop.phone ? "success" : "",
      email: prop.email || '',
      emailState: prop.email ? "success" : "",
      password: "",
      passwordState: prop.id ? "success" : "",
      name: prop.name || "",
      phone: prop.phone || "",
      phoneState: prop.phone ? "success" : "",
      date_of_birth: prop.date_of_birth || null,
      gender: prop.gender || "male",
      address: prop.address || "",
      torgid_region_id: prop.torgid_region_id || "",
      postalcode_number: prop.torgid_region_id && props.data.results ? this.findPostalCode(props.data.results, prop.torgid_region_id,'postal_code') : "",
      postalcode_city: prop.torgid_region_id && props.data.results ? this.findPostalCode(props.data.results, prop.torgid_region_id,'city') : "",
      subscribed: prop.id ? prop.subscribed : true,
      language: prop.language || "IS",
      kennitala: prop.kennitala || "",
      kennitalaState: prop.kennitala ? "success" : "",
      disabled: !prop.id,
      type: "Company_User",
      login: "company_user",
      profile_picture: prop.profile_picture || "",
      companyIds: props.user.companyIds ? props.user.companyIds : [],
      role_ids: prop.roles ? prop.roles.map(data => data.id) : [],
      groupState: prop.roles ? "success" : "",
      groupIds: props.user.groupIds ? props.user.groupIds.filter((item) => item !== null) : [],
      position: prop.position || "",
      companyState: props.user.companyIds ? "success" : "",
      open_for_barter: props.user.id ? props.user.open_for_barter : false,
      hidden: props.user.id ? props.user.hidden : false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.user.id !== state.id && !props.user.torgid_user) {
      return {
        id: props.user.id,
        email: props.user.email,
        name: props.user.name,
        phone: props.user.phone,
        date_of_birth: props.user.date_of_birth,
        gender: props.user.gender,
        address: props.user.address,
        postalcode: props.user.torgid_region_id || "",
        // postalcode_number: props.user.postalcode>0 && props.data.results ? this.constructor.findPostalCode(props.data.results, props.user.postalcode, 'postal_code') : "",
        postalcode_number: props.user.torgid_region_id>0 && props.data.results ? (props.data.results.find(x => x.id === props.user.torgid_region_id) ? props.data.results.find(x => x.id === props.user.torgid_region_id)['postal_code'] : '' )  : "",
        postalcode_city: props.user.torgid_region_id>0 && props.data.results ? (props.data.results.find(x => x.id === props.user.torgid_region_id) ? props.data.results.find(x => x.id === props.user.torgid_region_id)['city'] : '' ) : "",
        subscribed: props.user.id ? props.user.subscribed : true,
        language: props.user.language ? props.user.language : 'IS',
        userState: props.user.email || props.user.phone ? "success" : "",
        profile_picture: props.user.profile_picture || "",
        passwordState: props.user.id ? "success" : "",
        emailState: props.user.email ? "success" : "",
        phoneState: props.user.phone ? "success" : "",
        position: props.user.position || ""
      };
    }
    return null;
  }

  sendState = () => {
    return this.state;
  };
  verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRex.test(value);
  };

  verifyLength(value, length) {
    return value.length >= length;
  };

  findPostalCode = (data, id, type) => {
    let temp = data.find(x => x.id === id);
    if(temp) return temp[type];
    return ""
  };

  handleChange = (data, type) => {
    this.setState({ [type]: data });
  };

  
  handleChangeRegion = (data) => {
    if(!data) return;
    this.setState({ "torgid_region_id": data.id });
    this.setState({ "postalcode_number": data.postal_code });
    this.setState({ "postalcode_city": data.city });
  };

  checkEmail = () => {
    this.props.checkEmail(this.state.user);
    this.setState({disabled: false});
  };

  onUpload(data) {
    this.setState({ profile_picture: data });
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "login":
        if (this.verifyEmail(event.target.value) || (/^\d{7}$/.test(event.target.value))) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "lengthEqual":
        if (event.target.value.length === stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "phone":
        if (/^\d{7}$/.test(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (/^\d+$/.test(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  isValidated() {
    if (
      this.state.emailState === "success" &&
      this.state.passwordState === "success" &&
      this.state.phoneState === "success" &&
      (this.state.companyState === "success" || this.state.groupState === "success")
    ) {
      return true;
    } else {
      if (this.state.emailState !== "success") {
        this.setState({ emailState: "error" });
        if(this.state.disabled) return false;
      }
      if (this.state.passwordState !== "success") {
        this.setState({ passwordState: "error" });
      }
      if (this.state.phoneState !== "success") {
        this.setState({ phoneState: "error" });
      }
      if (this.state.companyState !== "success") {
        this.setState({ companyState: "error" });
      }
      if (this.state.groupState !== "success") {
        this.setState({ groupState: "error" });
      }
    }


    return false;
  }

  handleChangeCompany = (data) => {
    let ids =  data.map(data => data.id);
    this.setState({ companyIds: ids });
    this.setState({ companyState: "success" });
  };

  handleChangeRole = (data) => {
    let ids = data.map(data => data.id);
    this.setState({ role_ids: ids });
  };

  handleChangeGroup = (data) => {
    let ids = data.map(data => data.id);
    this.setState({ groupIds: ids });
    this.setState({ groupState: "success" });
  };

  render() {
    const { classes, user, companies, t, data,  company } = this.props;
    const isUpdating = !!user.id;

    const options = companies.results &&  companies.results.map((option) => {
      const firstLetter = option.name.charAt(0).toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...option,
      };
    });

    const optionsRegion = data.results &&  data.results.map((option) => {
      const firstLetter = option.torgid_region_name.name.toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...option,
      };
    });

    const optionsRoles = [{id: 4, name: "company_user"}, {id: 28, name: "group_admin"}];

    const optionsGroups = company.results && company.results.map((option) => {
      const firstLetter = option.name.toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...option,
      };
    });


    return (
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={4}>
          <PictureUpload imgHolder={this.state.profile_picture} msg={t('choose_picture')} onUpload={(data) => this.onUpload(data)} />
        </GridItem>
        <GridItem xs={12} sm={8}>
          <CustomInput
            success={this.state.userState === "success"}
            error={this.state.userState === "error"}
            labelText={ <span> {t('email_phone')} <small>{t('required')}</small> </span> }
            id="login"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: this.state.user,
              onChange: event => this.change(event, "user", "login"),
              endAdornment: (
                <Button
                  color="success"
                  type="button"
                  size="sm"
                  disabled={this.state.userState !== "success"}
                  onClick={this.checkEmail}
                  className={classes.inputAdornmentIcon}
                > Check Login </Button>
              )
            }}
          />
          <GridContainer justifyContent="center">
          <GridItem xs={12} sm={6}>
          <CustomInput
            success={!isUpdating && this.state.passwordState === "success"}
            error={this.state.passwordState === "error"}
            labelText={ <span> {t('password')} <small>{t('required')}</small> </span> }
            id="password"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              disabled: !!(this.state.disabled || isUpdating),
              onChange: event => this.change(event, "password", "length", 6),
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Lock className={isUpdating ? classes.inputAdornmentIcon : classes.disabledInputIcon} />
                </InputAdornment>
              )
            }}
          />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              success={this.state.kennitalaState === "success"}
              error={this.state.kennitalaState === "error"}
              labelText={ <span> {t('kennitala')} </span> }
              id="kennitala"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                disabled: this.state.disabled && true,
                value: this.state.kennitala || '',
                type: 'number',
                onChange: event => this.change(event, "kennitala", "lengthEqual", 10),
                endAdornment: (
                  <InputAdornment position="end" className={classes.inputAdornment}>
                    <CallToAction className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
          </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={12} sm={4}>
          <CustomInput
            labelText={ <span> {t('full_name')} </span> }
            id="name"
            formControlProps={{fullWidth: true }}
            inputProps={{
              disabled: this.state.disabled && true,
              value: this.state.name || '',
              onChange: event => this.change(event, "name"),
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
          <CustomInput
            success={this.state.emailState === "success"}
            error={this.state.emailState === "error"}
            labelText={ <span> {t('email')} <small>{t('required')}</small> </span> }
            id="email"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              disabled: this.state.disabled && true,
              value: this.state.email || '',
              onChange: event => this.change(event, "email", "email"),
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
          <CustomInput
            success={this.state.phoneState === "success"}
            error={this.state.phoneState === "error"}
            labelText={ <span> {t('phone_number')} <small>{t('required')}</small> </span> }
            id="phone"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              disabled: this.state.disabled && true,
              value: this.state.phone || '',
              onChange: event => this.change(event, "phone", "phone"),
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Phone className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={4}>
          <CustomInput
            success={this.state.addressState === "success"}
            error={this.state.addressState === "error"}
            labelText={ <span> {t('address')} </span> }
            id="address"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              disabled: this.state.disabled && true,
              value: this.state.address || '',
              onChange: event => this.change(event, "address"),
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <PersonPinCircle className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4} style={{marginTop:'20px'}}>
          <FormControl fullWidth>
            <Datetime
              value={this.state.date_of_birth || ''}
              onChange={e => this.handleChange(e, 'date_of_birth')}
              timeFormat={false}
              className={classes.inputAdornment}
              inputProps={{ placeholder: t('date_of_birth') }}
              dateFormat="yyyy-MM-D"
            />
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={4}>
          <CustomInput
            labelText={ <span> {t('position')} </span> }
            id="position"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              disabled: this.state.disabled && true,
              value: this.state.position || '',
              onChange: event => this.change(event, "position")
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={4} style={{marginTop:'20px'}}>
          <FormControlLabel
            control={
              <Radio
                disabled={this.state.disabled && true}
                checked={this.state.gender === "male"}
                onChange={e => this.handleChange(e.target.value, 'gender')}
                value="male"
                name="gender"
                aria-label="Male"
                icon={ <FiberManualRecord className={classes.radioUnchecked} /> }
                checkedIcon={ <FiberManualRecord className={classes.radioChecked} /> }
                classes={{ checked: classes.radio,  root: classes.radioRoot }}
              />
            }
            classes={{ label: classes.label,  root: classes.labelRoot }}
            label={t('male')}
          />
          <FormControlLabel
            control={
              <Radio
                disabled={this.state.disabled && true}
                checked={this.state.gender === "female"}
                onChange={e => this.handleChange(e.target.value, 'gender')}
                value="female"
                name="gender"
                aria-label="Female"
                icon={ <FiberManualRecord className={classes.radioUnchecked} /> }
                checkedIcon={ <FiberManualRecord className={classes.radioChecked} /> }
                classes={{ checked: classes.radio,  root: classes.radioRoot }}
              />
            }
            classes={{ label: classes.label,  root: classes.labelRoot }}
            label={t('female')}
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
          <Autocomplete
            id={"region"}
            onChange={(event, newValue) => {
              this.handleChangeRegion(newValue)
            }}
            value={this.state.torgid_region_id && this.state.postalcode_number ? {id: this.state.torgid_region_id, postal_code: this.state.postalcode_number, city: this.state.postalcode_city} : null}
            options={optionsRegion ? optionsRegion.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : []}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.postal_code+' '+option.city}
            getOptionSelected={(option, value) => option.id === value.id}
            style={{ marginTop: 10 }}
            renderInput={(params) => <TextField {...params} label={t('select_region')} variant="standard" />}
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
          <FormControl fullWidth className={classes.selectFormControl} variant="standard">
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}> {t('choose_language')} </InputLabel>
            <Select
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select }}
              disabled={this.state.disabled && true}
              value={this.state.language ? this.state.language.toUpperCase() : 'IS'}
              onChange={e => this.handleChange(e.target.value, 'language')}
              inputProps={{ name: "language", id: "user-lang"}}
            >
              <MenuItem
                classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                value="IS"
              >
                {t('icelandic')}
              </MenuItem>
              <MenuItem
                classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                value="EN"
              >
                {t('english')}
              </MenuItem>
            </Select>
          </FormControl>
        </GridItem>

        <GridItem xs={12} sm={4} >
          <Autocomplete
            multiple
            id={"roles"}
            onChange={(event, newValue) => {
              this.handleChangeRole(newValue)
            }}
            value={this.state.role_ids ? this.state.role_ids.map(data => data && optionsRoles.find(item => item.id === data)) : [] }
            options={optionsRoles}
            getOptionLabel={(option) => option?.name}
            getOptionSelected={(option, value) => value && option.id === value.id}
            style={{ marginTop: 10 }}
            renderInput={(params) => <TextField {...params} variant="standard" label={t('User role')+' '+t('required')} />}
          />
        </GridItem>

        {this.state.role_ids && (this.state.role_ids.includes(2) || this.state.role_ids.includes(3) || this.state.role_ids.includes(4)) &&
        <GridItem xs={12} sm={4} >
          <Autocomplete
            multiple
            id={"companies"}
            onChange={(event, newValue) => {
              this.handleChangeCompany(newValue)
            }}
            value={this.state.companyIds ? this.state.companyIds.map(data => options.find(item => item.id === data)) : [] }
            // value={this.state.companyId ? {id: this.state.companyId, name: this.state.companyName} : null}
            options={options ? options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : []}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option?.name}
            getOptionSelected={(option, value) => option.id === value.id}
            style={{ marginTop: 10 }}
            renderInput={(params) => <TextField {...params} variant="standard" label={t('companies')+' '+t('required')} />}
          />
        </GridItem>
        }

        {this.state.role_ids.includes(28) &&
        <GridItem xs={12} sm={4} >
          <Autocomplete
            multiple
            id={"groups"}
            onChange={(event, newValue) => {
              this.handleChangeGroup(newValue)
            }}
            value={this.state.groupIds ? this.state.groupIds.map(data => data && optionsGroups.find(item => item.id === data)) : [] }
            options={optionsGroups ? optionsGroups.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : []}
            getOptionLabel={(option) => option?.name}
            getOptionSelected={(option, value) => value && option.id === value.id}
            style={{ marginTop: 10 }}
            renderInput={(params) => <TextField {...params} variant="standard" label={t('User Group')+' '+t('required')} />}
          />
        </GridItem>
        }

        <GridItem xs={12} sm={10} >
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={4} className={classes.subscribed} style={{marginTop: '20px'}}>
              <div className={classes.block}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={this.state.disabled && true}
                      checked={this.state.subscribed || ''}
                      onChange={e => this.handleChange(e.target.checked, 'subscribed')}
                      value="false"
                      classes={{switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar}}
                    />
                  }
                  classes={{ label: classes.label }}
                  label={t('subscribe')}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={4} className={classes.subscribed} style={{marginTop: '20px'}}>
              <div className={classes.block}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={this.state.disabled && true}
                      checked={this.state.open_for_barter || false}
                      onChange={e => this.handleChange(e.target.checked, 'open_for_barter')}
                      value="false"
                      classes={{switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar}}
                    />
                  }
                  classes={{ label: classes.label }}
                  label={'Open to exchange'}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={3} className={classes.subscribed} style={{marginTop: '20px'}}>
            <div className={classes.block}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={this.state.disabled && true}
                    checked={this.state.hidden || false}
                    onChange={e => this.handleChange(e.target.checked, 'hidden')}
                    value="false"
                    classes={{switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar}}
                  />
                }
                classes={{ label: classes.label }}
                label={'Hidden'}
              />
            </div>
          </GridItem>
          </GridContainer>
        </GridItem>

      </GridContainer>
    );
  }
}

export default withTranslation('',{ withRef: true })(Step1);